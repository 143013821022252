import * as React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CDefinition,
  ConceptMedia,
  CFixedImg,
  LContact,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'BARBER SHOP',
            sub: '理容室',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/facilities/barber/kv.png',
              },
              imgSp: {
                src: '/assets/images/facilities/barber/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '館内施設・サービス',
                path: '/facilities/',
              },
            ],
            current: {
              label: '理容室',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <CFixedImg
            exClass="u_mb40_pc u_mb20_sp"
            width={840}
            img={{
              src: '/assets/images/facilities/barber/img_barber.png',
              alt: '理容室',
            }}
          />
          <h2 className="c_headingLv2 u_colorGold">
            50F 理容室 <br className="u_sp" />
            「YOSHIZAWA Inc. PREMIUM」
          </h2>
          <CDefinition
            exClass="u_mb20"
            data={[
              {
                title: '最終営業日',
                text: (
                  <>
                    2025年3月22日（土）18:30まで
                  </>
                ),
              },
              {
                title: '営業時間',
                text: (
                  <>
                    平　日：10:00～20:00
                    <br />
                    土・日・祝日：9:00～18:30
                  </>
                ),
              },
              {
                title: 'ご予約・お問合せ',
                text: (
                  <>
                    <a href="tel:0452250307">TEL 045-225-0307</a>
                  </>
                ),
              },
              {
                title: 'ウェブサイト',
                text: (
                  <>
                    <Link
                      to="https://yoshizawa-inc.com/yokohama/"
                      target="_blank"
                      rel="noopner noreferrer"
                    >
                      yoshizawa-inc.com/yokohama/
                    </Link>
                  </>
                ),
              },
              {
                title: '料金',
                text: <>ベーシックカットコース　6,050円～</>,
              },
            ]}
          />
          <ul className="c_noteList u_mb40">
            <li>料金には消費税が含まれます。</li>
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
